import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Spacer, Wrapper } from "../styles/layout"
import { Heading1 } from "../styles/typography"
import renderAst from '../templates/renderAst'

export const pageQuery = graphql`
  query AboutQuery($id: String!){
		markdownRemark(id: { eq: $id }) {
      id
			html
			htmlAst
			excerpt(pruneLength: 140)
      frontmatter {
        title
      }
    }
  }
`
const AboutPage = ({ data }) => {
	const { markdownRemark } = data // data.markdownRemark holds your post data
	const { frontmatter, htmlAst, excerpt } = markdownRemark

	return (
		<Layout className="page">
			<SEO
				title={`${frontmatter.title} - learning how to improve the way you practice golf`}
				description={excerpt}
			/>
			<Spacer height={20} />
			<Wrapper>
				<Heading1>{frontmatter.title}</Heading1>
				<Spacer height={5} />
				<article>
					<div>
						{renderAst(htmlAst)}
					</div>

				</article>
			</Wrapper>
		</Layout>
	)
}

export default AboutPage